import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_MAPPED_SYMBOLS,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  authGql,
  betsByInitialRoundId,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  setBetAmount,
  setBonuses,
  setBottomContainerTotalWin,
  setBrokenBuyFeature,
  setBrokenGame,
  setBrokenPickem,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  isBuyFeatureEnabled,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });
      const replayData = replayBetFreeSpins.data.betsByInitialRoundId;
      if (replayData.length) {
        const replayFreeSpins = replayData.map((e) => e.id);
        if (replayData.length > 1) {
          replayFreeSpins.splice(0, 1);
          setReplayBet(replayFreeSpins[1]);
          setReplayFreeSpinReelSetId(replayData[2].reelSetId);
        } else {
          setReplayFreeSpinReelSetId(replayData[0].reelSetId);
        }
        setReplayFreeSpinBets(replayFreeSpins);
      }
    };
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const userData = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        settings: slot.settings,
        previewImage: slot.previewImage,
        icons: slot.icons,
        reels: slot.reels,
        lineSets: slot.lineSets,
        lines: slot.lines,
        isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
      });

      let coinValue;
      let coinAmount;
      if (setBrokenGame() || setBrokenPickem() || setBrokenBuyFeature()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }

      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0].coinAmountMultiplier);
    };

    const checkBrokenGame = async () => {
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      // const userBonusData: ApolloQueryResult<{
      //   userBonuses: UserBonus[];
      // }> = JSON.parse(JSON.stringify(userBonusData1));
      // userBonusData.data.userBonuses.push({
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 2,
      //   totalWinAmount: 250000,
      //   coinAmount: 1,
      //   coinValue: 1000,
      //   id: '0f9ec2fe-8493-4daf-83fa-a24c9f6fb0af',
      //   bonusId: '0f9ec2fe-8493-4daf-83fa-a24c9f6fb0af',
      // });
      setGameMode(GameMode.REGULAR);

      if (userBonusData.data.userBonuses.length > 0) {
        const buyFeatureBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusesId[GameMode.BUY_FEATURE],
        );
        const pickemBonus = userBonusData.data.userBonuses.find(
          (e: UserBonus) => e.bonusId === bonusesId[GameMode.PICKEM],
        );
        const freeSpinBonus = userBonusData.data.userBonuses.find(
          (e: UserBonus) => e.bonusId === bonusesId[GameMode.FREE_SPINS],
        );
        const freeRoundBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
        );
        const fsTotalAmount = freeSpinBonus?.totalWinAmount
          ? freeSpinBonus.totalWinAmount / freeSpinBonus.coinValue
          : 0;
        const frbTotalAmount = freeRoundBonus?.totalWinAmount
          ? freeRoundBonus.totalWinAmount / freeRoundBonus.coinValue
          : 0;
        const pickemTotalAmount = pickemBonus?.totalWinAmount ? pickemBonus.totalWinAmount / pickemBonus.coinValue : 0;

        // const freeRoundBonus = {
        //   ...(setCurrentBonus() as UserBonus),
        //   isActive: true,
        //   gameMode: GameMode.FREE_ROUND_BONUS,
        //   currentRound: 0,
        //   rounds: 10,
        //   totalWinAmount: 0,
        //   coinAmount: 1,
        //   coinValue: 100,
        //   id: '59bf10b3-a0e7-4346-86bc-cfca305ee896',
        //   isFreeBet: true,
        //   bonusId: '59bf10b3-a0e7-4346-86bc-cfca305ee896',
        // };

        if (freeRoundBonus) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
          if (userBonusData.data.userBonuses.length === 1) {
            setBrokenGame(true);
            setGameMode(GameMode.FREE_ROUND_BONUS);
            setCurrentBonus({
              ...(freeRoundBonus as UserBonus),
              isActive: true,
              gameMode: GameMode.FREE_ROUND_BONUS,
              currentRound: 0,
              rounds: freeRoundBonus.rounds,
              totalWinAmount: frbTotalAmount,
              coinAmount: freeRoundBonus.coinAmount,
              coinValue: freeRoundBonus.coinValue,
              id: freeRoundBonus.id,
            });
            if (frbTotalAmount) {
              setBottomContainerTotalWin(frbTotalAmount);
              setFreeRoundsTotalWin(frbTotalAmount);
            }
          } else if (freeSpinBonus?.data.frbReferenceId || pickemBonus?.data.frbReferenceId) {
            setFreeRoundsBonus({
              ...freeRoundBonus,
              isActive: true,
              gameMode: GameMode.FREE_ROUND_BONUS,
              currentRound: 0,
              rounds: freeRoundBonus.rounds,
              totalWinAmount: frbTotalAmount,
              coinAmount: freeRoundBonus.coinAmount,
              coinValue: freeRoundBonus.coinValue,
              id: freeRoundBonus.id,
            });
            setFreeRoundsTotalWin(frbTotalAmount - (fsTotalAmount || pickemTotalAmount || 0));
          }
        }

        if (buyFeatureBonus) {
          setCurrentBonus({ ...buyFeatureBonus! });
          setCurrentBonusId(buyFeatureBonus?.id);
          setBrokenBuyFeature(true);
        }
        if (pickemBonus) {
          setBrokenPickem(true);
          setGameMode(GameMode.PICKEM);
          setCurrentBonus({ ...pickemBonus! });
          setCurrentBonusId(pickemBonus!.id);
          setFreeSpinsTotalWin(pickemTotalAmount);
          setBottomContainerTotalWin(
            freeRoundBonus && pickemBonus?.data.frbReferenceId ? frbTotalAmount : pickemTotalAmount,
          );
        }

        if (freeSpinBonus) {
          setBrokenGame(true);
          setGameMode(GameMode.FREE_SPINS);
          setCurrentBonus({
            ...freeSpinBonus!,
            isActive: true,
            currentRound: 0,
          });
          setFreeSpinsTotalWin(fsTotalAmount);
          setBottomContainerTotalWin(
            freeRoundBonus && freeSpinBonus.data.frbReferenceId ? frbTotalAmount : fsTotalAmount,
          );
          setCurrentBonusId(setCurrentBonus().id);
          const userBonusBetsData = await client.query<{
            bets: ISlotHistoryData;
          }>({
            query: userBonusBetsGql,

            variables: {
              input: {
                filter: {
                  userBonusId: setCurrentBonus().id,
                },
              },
            },
            fetchPolicy: 'network-only',
          });
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: userBonusBetsData.data.bets.pageInfo.count,
            rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
            totalWinAmount: 0,
          });
        }
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES, ...SPINE_LOADER_TEXTURES(isMobile)],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, setSkipIntroScreen(), isSoundOn, setIsSuspended).then(
          () => {
            eventManager.emit(
              EventTypes.SOUND_INITIALIZED,
              AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
            );
          },
        );

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
